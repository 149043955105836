@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .contact_box {
    @apply flex border my-2 border-black w-[300px] rounded-full;
  }
  .order_box_top {
    @apply flex  items-center mb-5 border rounded-lg py-4 px-3 bg-gray-50;
  }
  .serial_number {
    @apply bg-primary px-3 py-1 text-white font-semibold mr-5 rounded-full;
  }
  .title {
    @apply font-semibold text-primary text-[18px];
  }
  .order_box_bottom {
    @apply mb-5 border mt-[-28px] bg-white px-4 rounded-lg py-4;
  }
  .text-primary-500 {
    @apply text-primary;
  }
}
